import {
  adminAndOwnerOnly,
  adminOwnerAndEditorOnly,
  adminOwnerAndManagerOnly,
  adminOwnerAndPhotographerOnly,
  adminOwnerAndQcOnly,
  adminOwnerAndStaffOnly,
} from "@utils/auth-utils";

import { ROUTES } from "@utils/routes";
import { useRouter } from "next/router";

export const siteSettings = {
  name: "PHOTOS_PACKAGE ASAP",
  description: "",
  logo: {
    url: "/logo.svg",
    alt: "PHOTOS_PACKAGE ASAP",
    href: "/",
    width: 128,
    height: 40,
  },

   
  defaultLanguage: "en",
  author: {
    name: "Buylowcal",
    websiteUrl: "https://redq.io",
    address: "",
  },
  headerLinks: [],
  authorizedLinks: [
    {
      href: ROUTES.PROFILE_UPDATE,
      labelTransKey: "authorized-nav-item-profile",
    },
    {
      href: ROUTES.LOGOUT,
      labelTransKey: "authorized-nav-item-logout",
    },
  ],
  currencyCode: "USD",
  sidebarLinks: {
    admin: [
      {
        href: ROUTES.DASHBOARD,
        label: "Dashboard",
        // icon: "DashboardIcon",
      },
      {
        href: ROUTES.SHOPS,
        label: "Workspace",
        // icon: "ShopIcon",
      },
      {
        href: ROUTES.COMPANY,
        label: "Companies",
        // icon: "ProductsIcon",
      },
      // {
      //   href: ROUTES.COMPANYUSER,
      //   label: "Company User",
      // },
      // {
      //   href: ROUTES.ADMIN_MY_SHOPS,
      //   label: "sidebar-nav-item-my-shops",
      //   icon: "MyShopIcon",
      // },
      {
        href: ROUTES.PRODUCTS,
        label: "Services",
        // icon: "ProductsIcon",
      },

      // {
      //   href: ROUTES.ATTRIBUTES,
      //   label: "sidebar-nav-item-attributes",
      //   // icon: "AttributeIcon",
      // },

      {
        href: ROUTES.CATEGORIES,
        label: "Services Categories",
        // icon: "CategoriesIcon",
      },
      {
        href: ROUTES.TAGS,
        label: "Tags",
        // icon: "TagIcon",
      },
      // {
      //   href: ROUTES.MANUFACTURERS,
      //   label: "sidebar-nav-item-manufacturers",
      //   icon: "DiaryIcon",
      // },
      // {
      //   href: ROUTES.AUTHORS,
      //   label: "sidebar-nav-item-authors",
      //   icon: "FountainPenIcon",
      // },
      {
        href: ROUTES.ORDERS,
        label: "Orders",
        // icon: "OrdersIcon",
      },
      {
        href: ROUTES.ORDER_STATUS,
        label: "Order Status",
        // icon: "OrdersStatusIcon",
      },
      {
        href: ROUTES.CREATE_ORDER,
        label: "Create Order",
        // icon: "CalendarScheduleIcon",
      },
      {
        href: ROUTES.USERS,
        label: "Users",
        // icon: "UsersIcon",
      },
      // {
      //   href: ROUTES.AGENTS,
      //   label: "Agents",
      //   // icon: "UsersIcon",
      // },
      {
        href: `/${ROUTES.STAFFS}`,
        label: "Teams",
        // icon: "UsersIcon",
        // permissions: adminAndOwnerOnly,
      },
      // {
      //   href: ROUTES.COUPONS,
      //   label: "sidebar-nav-item-coupons",
      //   icon: "CouponsIcon",
      // },
      {
        href: ROUTES.TAXES,
        label: "Taxes",
        // icon: "TaxesIcon",
      },
      // {
      //   href: ROUTES.SHIPPINGS,
      //   label: "sidebar-nav-item-shippings",
      //   icon: "ShippingsIcon",
      // },
      // {
      //   href: ROUTES.WITHDRAWS,
      //   label: "sidebar-nav-item-withdraws",
      //   icon: "WithdrawIcon",
      // },
      // {
      //   href: ROUTES.REFUNDS,
      //   label: "sidebar-nav-item-refunds",
      //   icon: "RefundsIcon",
      // },
      {
        href: ROUTES.GROUPS,
        label: "Layout Setting",
        // icon: "TypesIcon",
      },
      {
        href: ROUTES.SETTINGS,
        label: "Setting",
        // icon: "SettingsIcon",
      },
    ],
    shop: [
      {
        href: (shop: string) => `/`,
        label: "sidebar-nav-item-dashboard",
        // icon: "DashboardIcon",
        permissions: adminOwnerAndStaffOnly,
      },

      // {
      //   href: (shop: string) => `/${shop}${ROUTES.ORDERS}`,
      //   label: "sidebar-nav-item-orders",
      //   // icon: "OrdersIcon",
      //   permissions: adminOwnerAndStaffOnly,
      // },

      // {
      //   href: (shop: string) => `/${shop}${ROUTES.PHOTOS_PACKAGE}`,
      //   label: "Photo Package",
      //   // icon: "CalendarIcon",
      //   permissions: adminOwnerAndPhotographerOnly,
      // },

      // {
      //   href: (shop: string) => `/${shop}${ROUTES.VIRTUAL_TOUR}`,
      //   label: "Virtual Tour",
      //   // icon: "CalendarIcon",
      //   permissions: adminOwnerAndPhotographerOnly,
      // },
    ],
    editor: [
      {
        href: (shop: string) =>  `/`,
        label: "sidebar-nav-item-dashboard",
        // icon: "DashboardIcon",
        permissions: adminOwnerAndEditorOnly,
      },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.OPEN_JOBS}/?role=editor`,
      //   label: "Open Jobs",
      //   // icon: "QueueIcon",
      //   permissions: adminOwnerAndEditorOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.UPLOAD_EDITED_IMAGES}/?role=editor`,
      //   label: "Approved Jobs ",
      //   // icon: "UploadIcon",
      //   permissions: adminOwnerAndEditorOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.EARNINGS}/?role=editor`,
      //   label: "Under Review Jobs",
      //   // icon: "MoneyIcon",
      //   permissions: adminOwnerAndEditorOnly,
      // },
     
    ],

    qc: [
      {
        href: (shop: string) => `/`,
        label: "sidebar-nav-item-dashboard",
        // icon: "DashboardIcon",
        permissions: adminOwnerAndQcOnly,
      },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.QUALITY_CHECK_QUEUE}?role=qc`,
      //   label: "Quality Check Queue",
      //   // icon: "QueueIcon",
      //   permissions: adminOwnerAndQcOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.APPROVED_IMAGES}`,
      //   label: "Approved Images",
      //   // icon: "CheckIcon",
      //   permissions: adminOwnerAndQcOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.REJECTED_IMAGES}`,
      //   label: "Rejected Images",
      //   // icon: "CloseIcon",
      //   permissions: adminOwnerAndQcOnly,
      // },

      // {
      //   href: (shop: string) => `/${shop}${ROUTES.PROFILE}`,
      //   label: "Profile",
      //   // icon: "ProfileIcon",
      //   permissions: adminOwnerAndQcOnly,
      // },
    ],

    photographer: [
      {
        href: (shop: string) => `/`,
        label: "sidebar-nav-item-dashboard",
        // icon: "DashboardIcon",
        permissions: adminOwnerAndPhotographerOnly,
      },
      // {
      //   href: (shop: string) =>
      //     `/${shop}${ROUTES.OPEN_JOBS}/?role=photographer`,
      //   label: "Open Jobs",
      //   // icon: "AssignmentIcon",
      //   permissions: adminOwnerAndPhotographerOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.UPLOAD_EDITED_IMAGES}/?role=photographer`,
      //   label: "Approved Jobs ",
      //   // icon: "UploadIcon",
      //   permissions: adminOwnerAndEditorOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.EARNINGS}/?role=photographer`,
      //   label: "Under Review Jobs",
      //   // icon: "MoneyIcon",
      //   permissions: adminOwnerAndEditorOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.VIRTUAL_TOUR}/?role=photographer`,
      //   label: "Virtual Tour",
      //   // icon: "CalendarIcon",
      //   permissions: adminOwnerAndPhotographerOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.EARNINGS}`,
      //   label: "Earnings",
      //   // icon: "MoneyIcon",
      //   permissions: adminOwnerAndPhotographerOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.PROFILE}`,
      //   label: "Profile",
      //   // icon: "ProfileIcon",
      //   permissions: adminOwnerAndPhotographerOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.COMMUNICATION}`,
      //   label: "Communication",
      //   // icon: "MessageIcon",
      //   permissions: adminOwnerAndPhotographerOnly,
      // },
    ],
    manager: [
      {
        href: (shop: string) =>
          `${ROUTES.MANAGER_DASHBOARD}${shop}?role=manager`,
        label: "Dashboard",
        // icon: "DashboardIcon",
        permissions: adminOwnerAndManagerOnly,
      },
      // {
      //   href: (shop: string) =>
      //     `/${shop}${ROUTES.ORDERS_MANAGEMENT}?role=manager`,
      //   label: "Orders Management",
      //   // icon: "OrderIcon",
      //   permissions: adminOwnerAndManagerOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.ORDERS}?role=manager`,
      //   label: "sidebar-nav-item-orders",
      //   // icon: "OrdersIcon",
      //   permissions: adminOwnerAndManagerOnly,
      // },
      // {
      //   href: (shop: string) =>
      //     `/${shop}${ROUTES.PHOTOGRAPHER_MANAGEMENT}?role=manager`,
      //   label: "Photographer Management",
      //   // icon: "PhotographerIcon",
      //   permissions: adminOwnerAndManagerOnly,
      // },
      // {
      //   href: (shop: string) =>
      //     `/${shop}${ROUTES.EDITOR_MANAGEMENT}?role=manager`,
      //   label: "Editor Management",
      //   // icon: "EditorIcon",
      //   permissions: adminOwnerAndManagerOnly,
      // },
      // {
      //   href: (shop: string) =>
      //     `/${shop}${ROUTES.QUALITY_CONTROL}?role=manager`,
      //   label: "Quality Control",
      //   // icon: "QualityIcon",
      //   permissions: adminOwnerAndManagerOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.SETTINGS}?role=manager`,
      //   label: "Settings",
      //   // icon: "SettingsIcon",
      //   permissions: adminOwnerAndManagerOnly,
      // },
    ],
  },
  product: {
    placeholder: "/product-placeholder.svg",
  },
  avatar: {
    placeholder: "/avatar-placeholder.svg",
  },
};
